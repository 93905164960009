/**
 * 変数
*****************************************************/
let news = {}
news = {
  jsonPath: {}, // 取得用
  jsonData: {}, // 格納用
  categorys: {
    'info': 'Information',
    'ir': 'IR',
    'events_seminars': 'Events / Seminars',
    'events': 'Events',
    'seminars': 'Seminars',
    'webinars': 'Webinars',
    'seminars_webinars': 'Seminars / Webinars',
    'products': 'Products',
    'pr': 'News Release',
    'applications': 'Applications',
    'support': 'Support',
  },
  state: {
    pageType: 'news',
    number: {}, // 表示件数
    paged: {}, // 現在のページNo
    maxPaged: {}, // 最大ページNo
    offset: {}, // ページ送りの際の表示用
  },
  elem: {
    wrap: {}, // Newsエリア
    list: {}, // Newsリスト（出力するエリア）
  },
}

/*****************************************************
 * 処理
*****************************************************/
// 読み込み時実行
document.addEventListener("DOMContentLoaded", function () {
  const breadcrumbItems = document.querySelectorAll('.l-breadcrumb__list__item');

  // パンくずリストが存在しない場合はトップページと判定
  if (breadcrumbItems.length === 0) {
    news.state.pageType = 'top';
  }

  // 2番目のパンくずリスト項目を確認して処理
  if (breadcrumbItems.length > 1) {
    const secondBreadcrumb = breadcrumbItems[1].querySelector('a')?.innerText.trim() || '';
    
    if (secondBreadcrumb === 'News') {
      news.state.pageType = 'news';
    } else {
      news.state.pageType = 'other';
    }
  }

  // 3番目のパンくずリスト項目を確認して処理
  if (breadcrumbItems.length > 2) {
    const thirdBreadcrumb = breadcrumbItems[2].querySelector('a')?.innerText.trim() || '';
    
    // 3番目のパンくずに基づいた追加の条件を設定（例: さらにページタイプを詳細に分けたい場合）
    if (thirdBreadcrumb  === 'Scientific Instruments' || thirdBreadcrumb === 'Electron Microscope General' || thirdBreadcrumb === 'Magnetic Resonance Spectrometer General' || thirdBreadcrumb === 'Mass Spectrometer General' || thirdBreadcrumb === 'Semiconductor Equipment' || thirdBreadcrumb === 'Industrial Equipment' || thirdBreadcrumb === 'Medical Equipment') {
      news.state.pageType = 'news'; // 3番目のパンくずが特定の値の場合の処理
    } else {
      news.state.pageType = 'other';
    }
  }

  news.hash()

  const _news = document.querySelectorAll('.js-news')

  for(let i = 0; i < _news.length; i++) {
    const _cat = _news[i].dataset.category
    news.elem.wrap[_cat] = document.querySelectorAll('.js-news')[i]
    news.elem.list[_cat] = document.querySelectorAll('.js-news')[i].querySelector('.js-news-list')
    news.jsonPath[_cat] = _news[i].dataset.jsonPath
    news.state.number[_cat] = Number(_news[i].dataset.number)
    news.state.paged[_cat] = 1
    news.state.offset[_cat] = 0
    news.init(_cat,document.querySelectorAll('.js-news')[i])
  }
})

/*****************************************************
* 関数
*****************************************************/

/*
 JSON読込み
*/
news.init = function (_cat,_target) {
	const xhr = new XMLHttpRequest()
	xhr.open("GET", news.jsonPath[_cat])
	xhr.send()
	xhr.onreadystatechange = () =>{
		if (xhr.readyState == 4 && xhr.status == 200) {
      news.jsonData[_cat] = []
			news.jsonData[_cat] = JSON.parse(xhr.responseText).data
      if(news.jsonData[_cat].length === 0){
        if(news.state.pageType === 'news') {
          _target.querySelector('.js-news-noresult').classList.remove('-hide')
          _target.querySelector('.js-news-list').classList.add('-hide')
          if(_target.querySelector('.js-pagenation')){
            _target.querySelector('.js-pagenation').classList.add('-hide')
          }
        } else {
          _target.remove()
        }
        return
      }
      news.state.maxPaged[_cat] = Math.ceil(news.jsonData[_cat].length / news.state.number[_cat])
      news.generate(_cat)
      if(!document.querySelector('.js-pagenation')) return
      pagenation.init(_cat)
    } else if (xhr.readyState == 4 && xhr.status == 404) {
      if(news.state.pageType === 'news') {
        _target.querySelector('.js-news-noresult').classList.remove('-hide')
        _target.querySelector('.js-news-list').classList.add('-hide')
        if(_target.querySelector('.js-pagenation')){
          _target.querySelector('.js-pagenation').classList.add('-hide')
        }
      } else {
        _target.remove()
      }
      return
    }
  }
}

/*
  reset
*/
news.reset = function (_cat) {
  const _h = news.elem.list[_cat].clientHeight
  if(_h <= 0) return
  news.elem.list[_cat].style.height = _h + 'px'
}

/*
  ニュースリスト生成
*/
news.generate = function (_cat) {
  news.reset(_cat)
  gsap.to(news.elem.list[_cat], {
    opacity: 0,
    duration: 0.1,
    onComplete: () => { 
      news.elem.list[_cat].innerHTML = ''
      for (let i = news.state.offset[_cat]; i < news.jsonData[_cat].length; i++) {
        if(i >= (news.state.number[_cat] * news.state.paged[_cat]) || !news.jsonData[_cat][i]) break
        if(news.state.pageType === 'news' || news.state.pageType === 'top') {
          news.elem.list[_cat].appendChild(news.listHtml(news.jsonData[_cat][i],'news'))
        } else {
          news.elem.list[_cat].appendChild(news.listHtml(news.jsonData[_cat][i],'other'))
        }
      }
      news.elem.list[_cat].style.height = ''
      gsap.to(news.elem.list[_cat], {
        opacity: 1,
        duration: 0.2
      })
     },
  })
}

/*
 html成形
*/
news.listHtml = function (_data,_type) {
  const _li = document.createElement("li")

  let _class = 'link'
  let _catText = ''

  if(_type === 'news') {
    _li.classList.add('m-newslist__item')
    _catText = news.categorys[_data.news_cat_label]
    _catText = '<p class="tag tag--'+_data.news_cat_label+'">'+_catText+'</p>'
    _class = 'm-newslist__item__link'
  } else {
    _li.classList.add('m-news__item')
  }

  const _target = (_data.link_target === 'false' || _data.link_target === 'FALSE' || _data.link_target === 'internal')? '' : ' target="_blank"'
  let _linkType = ''
  let _fileSize = _data.filesize === ''? '':'<span class="txt--sub"> ('+_data.filesize+') </span>'
  if(_data.link_target === 'outer') {
    _linkType = ' icon__newwindow'
  } else if(_data.link_target === 'pdf') {
    _linkType = ' icon__pdf'
  } else if(_data.link_target === 'word') {
    _linkType = ' icon__doc'
  } else if(_data.link_target === 'excel') {
    _linkType = ' icon__xls'
  } else if(_data.link_target === 'zip') {
    _linkType = ' icon__zip'
  }

	const _inner = '\
      <p class="date">' +_data.date.replace(/年|月/g, '/').replace(/日/g, '')+ '</p>\
      ' +_catText+ '\
      <p class="txt"><span class="txt__link' +_linkType+ '">' +_data.title+_fileSize+ '</span></p>\
      <a class="' +_class+ '" href="' +_data.link_url+ '"'+_target+'></a>\
  </li>'
  _li.innerHTML = _inner
  return _li
}

/*
 ハッシュ（IR用）
*/
news.hash = function () {
  let _hash = location.hash
  if(_hash && news.state.pageType === 'news'){
    _hash = _hash.replace("#", "")
    document.querySelector('[data-hash="'+_hash+'"]').click()
  }
}